import { Box, Typography } from "@pankod/refine-mui";
import { useList } from "@pankod/refine-core";
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { IStore } from 'interfaces/common';
import { useNavigate } from "@pankod/refine-react-router-v6";
import Loader from 'components/common/Loader';
const Stores = () => {
    const navigate = useNavigate();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
      });
    const { data: data, isLoading, isError }= useList<IStore>({
        resource: "driver/orders",
    });
    const stores =  data?.data ?? [];
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{ width: "100%",height:"100%"}}>
            { isLoaded && <GoogleMap
            mapContainerStyle={{width:"100%",height:"100%"}}
            center={{lat: 35.6986139, lng: -0.6184084}}
            zoom={12}
            options={{
                disableDefaultUI:true,
            }}
            >
                {/* <Marker  position={{ lat: 35.51, lng: -0.61}} /> */}
                {stores?.map((store) => {
                    return <MarkerF key={store.id} onClick={() => {
                        navigate(`/stores/show/${store.id}`)
                    }} position={{ lat: Number(store?.latitude), lng: Number(store?.longitude)}} />
                })}
               
            </GoogleMap>}
    </Box>
  )
}

export default Stores